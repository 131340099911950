import { useEffect, useState } from "react"
import { APISendMessage } from "../../apis/misc"
import { useDispatch } from 'react-redux';

export const Contact = (props) => {
  const [msgIsSent, setMsgIsSent] = useState(false)

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    validateForm();
  }, [name, email, message]);

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(email);
    const isFormValid = name && isEmailValid && message;
    setIsSubmitDisabled(!isFormValid);
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const name = e.target[0].value
    const email = e.target[1].value
    const message = e.target[2].value
    dispatch(APISendMessage(email, name, undefined, message, (success, error) => {
      if (success) {
        setMsgIsSent(true)
      }
    }))
  }

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div>
            <h3 style={{  color:'#fff', marginTop: '0px', marginBottom: '25px', fontWeight: '400' }}>Contact Info</h3>
            <div className="row">
              <div className='contact-item col-lg-4 col-md-6 col-sm-6'>
                <p>
                  <span>
                    <i className='fa fa-map-marker' style={{ width: '16px', textAlign: 'center' }}></i>Corporate Address
                  </span>
                  8 The Green, STE 300,
                  <br />
                  Dover, County of Kent
                  <br />
                  Delaware 19901, USA
                </p>
              </div>
              <div className='contact-item col-lg-4 col-md-6 col-sm-6'>
                <p>
                  <span>
                    <i className='fa fa-map-marker' style={{ width: '16px', textAlign: 'center' }}></i>Head Office
                  </span>
                  6175 Blvd Métropolitain E
                  <br />
                  Montréal, QC, H1P 1X7
                  <br />
                  Canada
                </p>
              </div>
            </div>
            <h3 style={{  color:'#fff', marginTop: '25px', marginBottom: '25px', fontWeight: '400' }}>Product and Service Support</h3>
            <div className="row justify-content-between">
              <div className="column col-sm-6 col-12">
                <div className='contact-item'>
                  <p>
                    <span>
                      <i className='fa fa-envelope' style={{ width: '16px', textAlign: 'center' }}></i>Email
                    </span>{' '}
                    info@mcjtools.com
                  </p>
                </div>
                <div className='contact-item'>
                  <p>
                    <span>
                      <i className='fa fa-phone' style={{ width: '16px', textAlign: 'center' }}></i>Phone
                    </span>{' '}
                    +1 (302) 203-9688
                  </p>
                </div>
                <h3 style={{  color:'#fff', marginTop: '25px', marginBottom: '25px', fontWeight: '400' }}>Express Shipping</h3>
                <div className="row">
                  <div className='contact-item'>
                    <p>
                      <span>
                        <i className='fa fa-truck' style={{ width: '16px', textAlign: 'center' }}></i>Free for orders of USD 60 or more
                      </span>{' '}
                      <span>
                        <i className='fa fa-usd' style={{ width: '16px', textAlign: 'center' }}></i>Flat rate $6 for orders of less than USD 60
                      </span>{' '}
                    </p>
                  </div>
                </div>
              </div>
              <div className="column col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                  { msgIsSent && (
                    <div className='column p-3' style={{ backgroundColor: '#fff', borderRadius: '5px' }}>
                      <span className="row p-2 justify-content-center"
                        style={{ color: 'black', textAlign: 'center', fontSize: '20px', fontFamily: 'Reckless' }}>
                          Thanks for your message!
                      </span>
                    </div>
                  )}
                  { !msgIsSent && (
                    <form className='column p-3'
                      style={{ backgroundColor: '#fff', borderRadius: '5px' }}
                      onSubmit={ handleSubmit }>
                      <span className="row p-2 justify-content-center"
                        style={{ color: 'black', textAlign: 'center', fontSize: '20px', fontFamily: 'Reckless' }}>
                          Leave Message
                      </span>
                      <input className="p-2 mt-2 w-100"
                        style={{ border: '1px solid #CCC', fontSize: '14px', borderRadius: '5px' }}
                        placeholder="Enter your Name"
                        onChange={ (e) => { setName(e.target.value) }} />
                      <input className="p-2 mt-2 w-100"
                        style={{ border: '1px solid #CCC', fontSize: '14px', borderRadius: '5px' }}
                        placeholder="Enter a valid email address"
                        onChange={ (e) => { setEmail(e.target.value) }} />
                      <textarea className="p-2 mt-2 w-100"
                        style={{ border: '1px solid #CCC', fontSize: '14px', borderRadius: '5px' }}
                        rows={3}
                        placeholder="Enter your message"
                        onChange={ (e) => { setMessage(e.target.value) }} />
                      <div className="mt-2 d-flex flex-row justify-content-center">
                        <button
                          disabled={isSubmitDisabled}
                          style={{ fontSize: '16px', fontWeight: '400', width: '100px',
                            border: isSubmitDisabled ? '1px solid #CCC' : '1px solid #ffc107',
                            borderRadius: '5px',
                            padding: '4px',
                            color: isSubmitDisabled ? '#666' : '#333', 
                            backgroundColor: isSubmitDisabled ? '#ccc' : '#ffc107',
                            cursor: isSubmitDisabled ? 'not-allowed' : 'pointer' }}>
                            Submit
                        </button>
                      </div>
                    </form>
                  )}
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/themcjtools/" target="_blank" rel="noreferrer">
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/McJTools" target="_blank" rel="noreferrer">
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCIiyYhzIMc6fE5TI8QgB9Xw" target="_blank" rel="noreferrer">
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}