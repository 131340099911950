import React, { Component } from 'react'
import { Badge, Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import MCJ from '../../../common/def'
import '../../../common/common.css'
import { GetMonthName } from '../../../common/utils'
import './orderDetail.css'
import {
    APIGetOrders
} from '../../../apis/cart'
import {
    APIGetProductDetail
} from '../../../apis/products'

class OrderDetail extends Component {
    constructor() {
        super()
        this.state = {
            isLoadingPage: true
        }
    }

    async componentDidMount(){
        await this.props.APIGetOrders()
        const {id} = this.props
        const {orderList} = this.props.cartState

        let theOrder
        for (let index in orderList) {
            // eslint-disable-next-line eqeqeq
            if(orderList[index].id == id) {
                theOrder = orderList[index]
                break
            }
        }

        if (typeof theOrder !== 'undefined' && theOrder !== null &&
            typeof theOrder.items !== 'undefined' && theOrder.items !== null) {
            theOrder.items.forEach(prod => {
                this.props.APIGetProductDetail(prod.productId)
            });
        }

        this.setState({ isLoadingPage: false })
    }

    getPriceByQuantity = (quantity, productId) => {
        const { productDetailsById } = this.props.prodState
        const productDetail = productDetailsById[productId]
        const { userInfo } = this.props.userState
        const isDealer = typeof userInfo !== 'undefined' && userInfo !== null && userInfo.dealer === true
        if (typeof productDetail === 'undefined' || productDetail === null) {
            return null
        }
        let prices = productDetail.prices
        if (typeof prices === 'undefined' || prices === null || prices.length === 0) {
            return null
        }
        prices = prices.slice().sort((a, b) => (a.min > b.min) ? 1 : -1)
        let priceRange = undefined
        for (let i = 0; i < prices.length; i++) {
            priceRange = prices[i]
            if (isDealer && priceRange.dealer === true) {
                break
            } else if (quantity >= priceRange.min && quantity <= priceRange.max) {
                break
            }
        }
        if (priceRange !== undefined) {
            return priceRange.price
        }
        return null
    }

    renderOrderProducts = () => {
        const {id} = this.props
        const {orderList} = this.props.cartState
        let theOrder
        for (let index in orderList) {
            // eslint-disable-next-line eqeqeq
            if(orderList[index].id == id) {
                theOrder = orderList[index]
                break
            }
        }
        if (typeof theOrder === 'undefined' || theOrder === null) {
            return <Navigate to={'/cart'} />
        }
        if (typeof theOrder.items === 'undefined' || theOrder.items === null) {
            return null
        }
        
        const { allProductsById } = this.props.prodState
        let productList = theOrder.items.map(prod=>{
            // Get Product Info
            let theProd = allProductsById[prod.productId]
            if (typeof theProd === 'undefined' || theProd === null) {
                return null
            }
            let prdImg = MCJ.DefaultImgPrefix + theProd.thumbnail
            let prdPrice = `$${theProd.price}`
            let prdPriceByQuantity = this.getPriceByQuantity(prod.quantity, prod.productId)
            if (prdPriceByQuantity !== null) {
                prdPrice = prdPriceByQuantity
            }
            let prdQuantity = `Quantity:${prod.quantity}`
            let prdLinkUrl = `/product/${prod.productId}`
   
            return (
               <div style={{display: 'flex', fontSize: '14px', marginBottom: '8px'}} key={prod.productId}>
                   <Link to={prdLinkUrl}>
                       <img className='odImg' src={prdImg} alt={theProd.name}/>
                   </Link>
                   <div className='odProdInfo'>
                       <Link to={prdLinkUrl}>
                           <div>{theProd.name}</div>
                       </Link>
                       <div>{prdPrice}</div>
                       <div>{prdQuantity}</div>
                   </div>
               </div>
           )
       })

        return (
            <div className='orderProducts'>
                {productList}
                {/* {needCancel} */}
            </div>
        )
    }

    renderOrderSummary = () => {
        const {userInfo} = this.props.userState
        const {id} = this.props
        const {orderList} = this.props.cartState
        let order
        for (let index in orderList) {
            // eslint-disable-next-line eqeqeq
            if(orderList[index].id == id) {
                order = orderList[index]
            }
        }
        if (typeof order === 'undefined' || order === null) {
            return null
        }
        let feesView = null
        const fees = order.fees
        if (typeof fees !== 'undefined' && fees !== null) {
            feesView = fees.map((fee, i) => {
                return (
                    <tr>
                        <td>{fee.name}: </td>
                        <td style={{ textAlign: 'right' }}>{`US$${fee.fee}`}</td>
                    </tr>
                )
            })
        }
        return (
            <div className='orderSummary'>
                <div>
                    <div style={{ fontSize: '13px', fontWeight: '700' }}>Shipping Address</div>
                    <div style={{ fontSize: '13px'}}>{(order.shipping?.name || userInfo.fullName).toUpperCase()}</div>
                    <div style={{ fontSize: '13px'}}>{order.shipping?.address.toUpperCase()}</div>
                    <div style={{ fontSize: '13px'}}>{`${order.shipping?.city.toUpperCase()}, ${order.shipping?.state.toUpperCase()} ${order.shipping?.postal.toUpperCase()}`}</div>
                    <div style={{ fontSize: '13px'}}>{order.shipping?.country.toUpperCase()}</div>
                    <div style={{ fontSize: '13px'}}>{(order.shipping?.phone || (userInfo.phone || "")).toUpperCase()}</div>
                </div>
                <div style={{ marginLeft: '16px' }}>
                    <div style={{ fontSize: '13px', fontWeight: '700' }}>Billing Address</div>
                    <div style={{ fontSize: '13px'}}>{(order.billing?.name || userInfo.fullName).toUpperCase()}</div>
                    <div style={{ fontSize: '13px'}}>{order.billing?.address.toUpperCase()}</div>
                    <div style={{ fontSize: '13px'}}>{`${order.billing?.city.toUpperCase()}, ${order.billing?.state.toUpperCase()} ${order.billing?.postal.toUpperCase()}`}</div>
                    <div style={{ fontSize: '13px'}}>{order.billing?.country.toUpperCase()}</div>
                    <div style={{ fontSize: '13px'}}>{(order.billing?.phone ||(userInfo.phone || "")).toUpperCase()}</div>
                </div>
                <div style={{ marginLeft: '16px' }}>
                    <div style={{ fontSize: '13px', fontWeight: '700' }}>Order Summary</div>
                    <div style={{ fontSize: '13px'}}>
                        <table>
                            <tbody>
                                { feesView }
                                <tr>
                                    <td>Subtotal:</td>
                                    <td style={{ textAlign: 'right' }}>{`US$${order.summary?.subtotal}`}</td>
                                </tr>
                                <tr>
                                    <td>Discount: </td>
                                    <td style={{ textAlign: 'right' }}>{`US$${order.summary?.discount}`}</td>
                                </tr>
                                <tr>
                                    <td>Tax:</td>
                                    <td style={{ textAlign: 'right' }}>{`US$${order.summary?.tax}`}</td>
                                </tr>
                                <tr>
                                    <td>Total:</td>
                                    <td style={{ textAlign: 'right' }}>{`US$${order.summary?.total}`}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    renderPayStatus = () => {
        const {id} = this.props
        const {orderList} = this.props.cartState
        let theOrder
        for (let index in orderList) {
            // eslint-disable-next-line eqeqeq
            if(orderList[index].id == id) {
                theOrder = orderList[index]
            }
        }
        let paymentStatus = null
        if (theOrder.status === 'PAID') {
            paymentStatus = <h3><Badge bg='success'>Paid</Badge></h3>
        }
        return paymentStatus
    }

    renderEmptyMessage = () => {
        if (this.state.isLoadingPage) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Spinner animation='border' style={{ marginTop: '80px', marginBottom: '80px'}} />
                </div>
            )
        }
    }

    render() {
        let summary = this.renderOrderSummary()
        let details = this.renderOrderProducts()

        const {id} = this.props
        const {orderList} = this.props.cartState
        let theOrder
        for (let index in orderList) {
            // eslint-disable-next-line eqeqeq
            if(orderList[index].id == id) {
                theOrder = orderList[index]
                break
            }
        }
        
        if (typeof theOrder === 'undefined' || theOrder === null) {
            return (
                <div className='orderContainer container'>
                    {this.renderEmptyMessage()}
                </div>
            )
        }

        let orderDate = new Date(theOrder.createdAt)
        let strOrderDate = `${GetMonthName(orderDate.getMonth())}, ${orderDate.getDate()} ${orderDate.getFullYear()}`

        let paymentStatus = this.renderPayStatus()

        return (
            <div className='orderContainer container'>
                <div>
                    <h2>Order Details</h2>
                    <hr/>
                </div>
                {/* {shipInfo} */}
                <br/>
                <div className='odContent'>
                    <div className='orderHead'>
                        <span>Ordered on {strOrderDate}</span>
                        <div>
                            <span>Order # {theOrder.id}</span>
                            { (theOrder.invoice?.url !== undefined && theOrder.invoice?.url.length > 0) && (
                                <a style={{marginLeft: "8px"}} href={theOrder.invoice?.url} target="_blank" rel="noreferrer">
                                    Invoice
                                </a>
                            )}
                        </div>
                    </div>
                    {summary}
                    <br/>
                    {details}
                    <div className='orderStatus'>
                        {paymentStatus}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    cartState:state.cartState,
    userState:state.userState,
    prodState:state.prodState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetOrders,
        APIGetProductDetail
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail)