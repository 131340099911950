import React, { Component } from 'react'
import {  Helmet } from 'react-helmet';
import { Button } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import bkImg from '../../../assert/promotionBrush.jpg'
import MCJ from '../../../common/def'
import './promotionBrush.css'
import {APIGetPromotionInfo, ConfirmPromotionInfo} from '../../../apis/promotion'
import { connect } from 'react-redux'
import PromotionBrushSignUp from './promotionBrushSignUp'

class PromotionBrush extends Component {
    constructor(){
        super()
        this.state = {
            code:"",
            lastErrIndex:0,
            floatErrIndex:0,
            prevState:""
        }
    }

    onValueChange = e => {
        this.setState( prevState => {
            let nextFloat = prevState.floatErrIndex+1
            return {
                ...prevState,
                [e.target.name]:e.target.value,
                floatErrIndex:nextFloat
            }
        })
    }

    getInfo = ()=>{
        if(this.state.code !== "") {
            this.props.APIGetPromotionInfo(this.state.code)
        }
    }

    shouldComponentUpdate() {
        const {status} = this.props.promoState
        // eslint-disable-next-line eqeqeq
        if (status != this.state.prevState) {
            this.setState({
                prevState:status,
                lastErrIndex:0,
                floatErrIndex:0
            })
        }
        return true
    }

    componentWillUnmount(){
        this.props.ConfirmPromotionInfo()
    }

    renderCoupon = () => {
        const {status} = this.props.promoState
        let errMsg = ""
        if(this.state.lastErrIndex === this.state.floatErrIndex && status === MCJ.STATUS_ERROR) {
            errMsg = "Invalid coupon code."
        }
        return <>
            <img className='pmImg' src={bkImg} alt='promotion' />
            <div className='pmCoupon' style={{ marginTop: '16px' }}>
                <span>Coupon:</span>
                <input style={{ width: '120px', height: '40px', fontSize: '16px' }} name='code' value={this.state.code} onChange={this.onValueChange} placeholder='Coupon Code' />
                <Button style={{ width: '100px', height: '40px' }} variant='warning' onClick={this.getInfo}>Continue</Button>
                {/* <Link to={'/free/signup'}><Button variant='warning'>Continue</Button></Link> */}
            </div>
            <p className='pmError'>{errMsg}</p>
            <div className='pmCoupon'>
                Complete Sign Up to get free 10-pack of our premium bronze bore brushes
            </div>
            <ul className='pmCouponTips'>
                <li>Free shipping included</li>
                <li>No credit card information required</li>
            </ul>
        </>
    }

    renderSignUp = () => {
        return <><PromotionBrushSignUp /></>
    }

    render() {
        const {status} = this.props.promoState
        let content = null
        switch(status) {
            case MCJ.STATUS_INIT:
            case MCJ.STATUS_LOADING:
            case MCJ.STATUS_ERROR:
                content = this.renderCoupon()
                break
            case MCJ.STATUS_LOAD_OK:
                content = this.renderSignUp()
                break
            default:
                break
        }
        
        return (
            <div className='promotionBrush'>
                <Helmet>
                    <style>{'body { background-color: #1e2021; }'}</style>
                </Helmet>                       
                {content}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    promoState: state.promoState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetPromotionInfo,
        ConfirmPromotionInfo
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionBrush)