import React, { Component } from 'react'
import './products.css'
import MCJ from '../../../common/def'
import "../../../common/common.css"
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { withRouter } from '../../../common/withRouter'
import * as queryString from "query-string";
import { SetCategoryAction } from '../../../apis/products'

class Products extends Component {

    componentDidMount() {
        const { category } = queryString.parse(this.props.router.location.search)
        this.props.SetCategoryAction(category)
    }
    
    render() {
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        let { allProductsById, currCategoryId, productIdsByCategory } = this.props.prodState
        let content = <div className="prodList"></div>
        if (typeof currCategoryId === 'undefined' || currCategoryId === null) {
            currCategoryId = 1
        }
        let imageUrlPrefix = MCJ.DefaultImgPrefix
        if (typeof this.props.userState.system !== 'undefined') {
            imageUrlPrefix = this.props.userState.system.imageUrlPrefix
        }
        const productIds = productIdsByCategory[currCategoryId]
        let prodListView = []
        if (typeof productIds !== 'undefined' && productIds !== null) {
            productIds.forEach((prodId) => {
                let item = allProductsById[prodId]
                if (item) {
                    prodListView.push((
                        <Link
                            key={item.id}
                            className='prodItem'
                            style={{ textDecoration: 'none' }}
                            to={`${region}/product/${item.id}`}
                            state={{ prodInfo: item }}>
                            <img className="prodImg" alt='' src={`${imageUrlPrefix}${item.thumbnail}`} />
                            <span className="prodTitle">{item.name}</span>
                            { (region !== "/au" && region !== "/nz") && (
                                <span className='prodPrice'>${item.price}</span>
                            )}
                        </Link>
                    ))
                }
            })
        }
        if (prodListView.length === 0) {
            content = (
                <div style={{ textAlign: 'center' }}>
                    <Spinner animation='border' style={{ marginTop: '80px', marginBottom: '80px'}} />
                </div>
            )
        } else {
            content = <div className="prodList">{prodListView}</div>
        }
        return (
            <div className='prodListContainer container'>
                {content}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    prodState: state.prodState,
    userState: state.userState,
    authed: state.authState.authed
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        SetCategoryAction
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Products))