import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Logo from '../../common/logo'
import './signin.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { APISignUp } from '../../apis/auth'
import { withRouter } from '../../common/withRouter'

class SignUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
        email:"",
        emailErr:null,
        name:"",
        nameErr:null,
        phone:"",
        phoneErr:null,
        password:"ff",
        passwordErr:null,
        repassword:"",
        repasswordErr:null,
        isCreating:false,
        signUpErr:null
    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  verifyName = () => {
    if(typeof this.state.name === 'undefined' || this.state.name === null || this.state.name === "") {
        this.setState({
          nameErr: 'Enter your name',
        })
        return false
    }
    return true
  }

  validateEmail = () => {
    if(typeof this.state.email === 'undefined' || this.state.email === null || this.state.email === "") {
        this.setState({
          emailErr: 'Enter your email',
        })
        return false
    }
    //var pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var pattern = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

    let bEmailOK = pattern.test(this.state.email)
    if (bEmailOK) {
      return true
    }

    this.setState({
      emailErr: "Invalid email"
    })
    return false
  }

  verifyPhone = () => {
    if(typeof this.state.phone === 'undefined' || this.state.phone === null || this.state.phone === "") {
      this.setState({
        phoneErr: 'Enter your phone number',
      })
      return false
    }
    return true
  }

  validatePassword = () => {
    if(typeof this.state.password === 'undefined' || this.state.password === null || this.state.password.length < 6) {
          this.setState({
          passwordErr: 'Minimum 6 characters required',
        })
        return false
    }
    return true
  }

  validateRePassword = () => {
    if(this.state.password !== this.state.repassword) {
        this.setState({
          repasswordErr: 'Passwords do not match',
        })
        return false
    }
    return true
  }

  handleValueOnChange = (e)=>{
    let name = e.target.name
    let value = e.target.value 
    this.setState({
        [name]:value,
        [name+"Err"]:null,
        signUpErr:null
    })
  }

  signUp = async () => {
    // verify
    let isValidated = true
    isValidated = isValidated && this.validateEmail()
    //verifyPass = verifyPass && this.verifyName()
    //verifyPass = verifyPass && this.verifyPhone()
    isValidated = isValidated && this.validatePassword()
    isValidated = isValidated && this.validateRePassword()

    if (!isValidated) {
      return
    }

    this.setState({ isCreating: true })
    let signupObj = {
      email: this.state.email,
      password: this.state.password
    }
    await this.props.APISignUp(signupObj)
    this.setState({
      isCreating: false,
      signUpErr: this.props.authState.error
    })
  }

  renderSignUpForm = () => {
    return (
      <React.Fragment>
        <Form className='signinBox'>
            <h3>Create New Account</h3>
            <p className='tipLabel'>
              E-mail address
            </p>
            <Form.Group className='mb-3 inputer' controlId='formBasicEmail'>
              <Form.Control type='email' name='email' placeholder='Email' onChange={this.handleValueOnChange} />
            </Form.Group>
            <div className='errMsg'>{this.state.emailErr}</div>
            <p className='tipLabel'>
              Password
            </p>
            <Form.Group className='mb-3 inputer' controlId='formBasicPassword'>
              <Form.Control name='password' type='password' placeholder='At least 6 characters' onChange={this.handleValueOnChange} />
            </Form.Group>
            <div className='errMsg'>{this.state.passwordErr}</div>
            <p className='tipLabel'>
              Re-enter password
            </p>
            <Form.Group className='mb-3 inputer' controlId='formBasicRePassword'>
              <Form.Control name='repassword' type='password' placeholder='' onChange={this.handleValueOnChange} />
            </Form.Group>
            <div className='errMsg'>{this.state.repasswordErr}</div>
            <div className='errMsg'>{this.state.signUpErr}</div>
            <div className='centerWarpper'>
              <Button variant='warning' onClick={this.signUp} style={{ width: '360px' }}  disabled={this.state.isCreating}>
                Create New Account
              </Button>
            </div>
          </Form>
          <div className={'textDivider '}>
            <span className='signUpTipDivider'>Already have an account?</span>
          </div>
          <div style={{ marginBottom: '16px' }}>
            <Link to='/signin' className='d-flex justify-content-center' style={{ textDecoration: 'none' }}>
              <Button variant='secondary' style={{ width: '360px' }}>
                Sign In
              </Button>
            </Link>
          </div>
      </React.Fragment>
    )
  }

  renderSignUpOK = () => {
    var region = this.props.router.params.region || ""
    if (region.length > 0) {
        region = `/${region}`
    }
    return (
      <React.Fragment>
        <h3 className='signUpOK'>Account Created!</h3>
        <hr/> 
        <p>
          <Link to={`${region}/`}>
            <Button variant="warning" style={{ marginBottom: '16px' }}>Continue Shopping</Button>
          </Link>
        </p>
      </React.Fragment>
    )
  }

  render () {
    let content = null
    if (this.props.authed) {
      content = this.renderSignUpOK()
    } else {
      content = this.renderSignUpForm()
    }
    
    return (
      <div className='root'>
        <div className='top'>
          <Logo />
          {content}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authState: state.authState,
  authed: state.authState.authed
})

const mapDispatchToProps = dispatch =>{
  return bindActionCreators(
    {
      APISignUp
    },
    dispatch
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp))