import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Carousel } from 'react-bootstrap';
import imgShotshowIWAShow from '../../assert/shotshow_iwashow.png'
import introbg from '../../assert/intro-bg.jpg'
import oembg from '../../assert/oem_bg.jpg'
import cncbg from '../../assert/cnc.jpg'
import oembg2 from '../../assert/oem.jpg'
import "./carousel.css";
import { SetCategoryAction } from '../../apis/products';
import { Link } from 'react-router-dom';
import { withRouter } from '../../common/withRouter'

class LandingCarousel extends Component {

  render() {
    var region = this.props.router.params.region || ""
    if (region.length > 0) {
        region = `/${region}`
    }
    // Reloading
    const prods1 = [
      {
        "img": "https://mcjtools-prod-assets.s3.amazonaws.com/images/3010/3010_thumbnail.jpg",
        "title": 'PRIMER POCKET CLEANER SET',
        "url": "product/21",
        "id": 21
      },
      {
        "img": "https://mcjtools-prod-assets.s3.amazonaws.com/images/4300/4300_thumbnail.jpg",
        "title": 'ROTARY CASE TRIMMER CUTTER',
        "url": "product/32",
        "id": 32
      },
      {
        "img": "https://mcjtools-prod-assets.s3.amazonaws.com/images/3111/3111_thumbnail.jpg",
        "title": 'DIE LOCK RING',
        "url": "product/2",
        "id": 2
      },
      {
        "img": "https://mcjtools-prod-assets.s3.amazonaws.com/images/3021/3021_thumbnail.jpg",
        "title": 'AUTO PRIMER FEEDER TUBE',
        "url": "product/63",
        "id": 63
      }
    ]
    // Cleaning & Maintenance
    const prods2 = [
      {
        "img": "https://mcjtools-prod-assets.s3.amazonaws.com/images/bs/bore-snake9mm-375-38-thumbnail.jpg",
        "title": 'Bore Snake Rope cleaner',
        "url": "product/249",
        "id": 249
      },
      {
        "img": "https://mcjtools-prod-assets.s3.amazonaws.com/images/bs/bore-snake-223-22-5_56-thumbnail.jpg",
        "title": 'Bore Snake Rope cleaner',
        "url": "product/250",
        "id": 250
      },
      {
        "img": "https://mcjtools-prod-assets.s3.amazonaws.com/images/cb/chamber-brush-22cal-10-thumbnail.jpg",
        "title": 'Chamber Brush',
        "url": "product/248",
        "id": 248
      },
      {
        "img": "https://mcjtools-prod-assets.s3.amazonaws.com/images/pistol-bore-brush-38/pb38_thumbnail.jpg",
        "title": 'Bronze Bore Brush',
        "url": "product/54",
        "id": 54
      }
    ]
    return (
      <Carousel>
        <Carousel.Item className='carousel-item'>
          <img className="d-block w-100 carousel-item-bg-img" src={introbg} alt="Shot Show" />
          <Carousel.Caption>
            <div className="row">
              <div className="col-md-6 pt-0 pb-2 d-flex flex-column align-items-center justify-content-center">
                <img style={{ width: '320px', height: '133px' }} src={imgShotshowIWAShow} alt='IWA SHOW & Shot Show' />
                <h1 className="mt-0" style={{ color: '#f4b231', textShadow: '0px 0px 8px #000000', fontSize: '24px' }}>
                  SHOT SHOW & IWA SHOW
                </h1>
              </div>
              <div className="col-md-6 pl-4 d-flex flex-column align-items-start justify-content-start">
                <h1 style={{ color: '#f4b231', textShadow: '0px 0px 16px black', textAlign: 'left' }}>BRONZE CHAMBER BRUSHE</h1>
                <p style={{ color: '#fff', textShadow: '0px 0px 16px black', textAlign: 'left' }}>Tapered bronze bristles scrub the chamber, neck and shoulder. Brass stem is cold welded to the core, and the tip is looped.</p>
                <div style={{ flexGrow: 1 }}></div>
                <Link className='btn btn-warning' to={'products?category=5'} onClick={()=> {
                  this.props.SetCategoryAction(5)
                  if (window.innerWidth < 992) {
                    document.querySelector('.navbar-toggler').click()
                  }
                }}>
                  <i className="fa fa-shopping-cart me-2"></i>
                  Buy Now
                </Link>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        {/* <Carousel.Item className='carousel-item'>
          <img className="d-block w-100 carousel-item-bg-img" src={oembg} alt="News" />
          <Carousel.Caption>
            <div className="row">
              <div className="col-md-6 pt-0 pb-2 d-flex">
                <p style={{ textAlign: 'left', color: 'white', fontSize: '32px', fontWeight: '700' }}>
                  <span style={{ borderBottom: '4px solid #f4b231' }}>
                    News
                  </span>
                </p>
              </div>
              <div className="row">
                <div className="col-md-6 pt-0 pb-2 d-flex">
                  <p style={{ textAlign: 'left', fontSize: '20px', fontWeight: 'bolder' }}>
                    We are thrilled to announce that Accuracy Reloading has become our newest partner!
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="d-flex">
                    <a href={'https://www.accuracyreloading.it'} target="_blank" rel="noopener noreferrer" className="partner-item">
                      <img style={{ width: '100%', maxWidth: '320px', backgroundColor: 'white', padding: '16px', borderRadius: '10px' }} alt='accuracyreloading logo'
                        src={'https://www.accuracyreloading.it/new/wp-content/uploads/2016/12/ll.png'} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item> */}
        <Carousel.Item className='carousel-item'>
          <img className="d-block w-100 carousel-item-bg-img" src={oembg} alt="OEM" />
          <Carousel.Caption>
            <h1>
              OEM Service
            </h1>
            <div className="row">
              <div className="col-md-6 pt-0 pb-2 d-flex">
                <p style={{ textAlign: 'left' }}>
                  McJ Tools' OEM Contract Manufacturing Service consistently delivers top-quality products and services to our customers. Our skilled workforce is dedicated to surpassing your expectations, employing meticulous planning and communication for each project to ensure timely delivery and optimal quality.
                </p>
              </div>
              <div className="col-md-6 p-0 d-flex flex-column">
                <ul style={{ textAlign: 'left' }}>
                  <li>Flexible and Scalable Production.</li>
                  <li>Customization and Design.</li>
                  <li>Commitment to Quality.</li>
                  <li>Intellectual Property Protection.</li>
                  <li>Cost Advantages.</li>
                </ul>
                <a href={`${region}/oem`} className="btn btn-warning align-self-center">
                  <i className="fa fa-envelope me-2"></i>
                  Inquiry
                </a>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className='carousel-item'>
          <img className="d-block w-100 carousel-item-bg-img" src={oembg2} alt="Reloading" />
          <Carousel.Caption>
            <div className="col">
              <div className="d-flex">
                <p style={{ textAlign: 'left', color: 'white', fontSize: '32px', fontWeight: '700' }}>
                  <span style={{ borderBottom: '4px solid #f4b231' }}>
                    Reloading
                  </span>
                </p>
              </div>
              <div className='carousel-products-list'>
                {prods1.map(prod => {
                  return (
                    <a key={prod.id} href={prod.url} style={{ textDecoration: 'none' }}>
                      <div>
                        <div style={{ border: '8px solid rgba(255, 255, 255, 0.3)' }}>
                          <img src={prod.img} alt={prod.title} style={{ width: '100%', display: 'block', backgroundColor: 'white', maxHeight: '128px', objectFit: 'contain' }} />
                        </div>
                        <div style={{ color: '#f4b231', backgroundColor: 'rgba(255, 255, 255, 0.1)', textAlign: 'center', fontSize: '12px', fontWeight: '600', padding: '4px' }}>
                          {prod.title}
                        </div>
                      </div>
                    </a>
                  )
                })}
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className='carousel-item'>
          <img className="d-block w-100 carousel-item-bg-img" src={cncbg} alt="Cleaning & Maintenance" />
          <Carousel.Caption>
            <div className="col">
              <div className="d-flex">
                <p style={{ textAlign: 'left', color: 'white', fontSize: '32px', fontWeight: '700' }}>
                  <span style={{ borderBottom: '4px solid #f4b231' }}>
                    Cleaning & Maintenance
                  </span>
                </p>
              </div>
              <div className='carousel-products-list'>
                {prods2.map(prod => {
                  return (
                    <a key={prod.id} href={prod.url} style={{ textDecoration: 'none' }}>
                      <div>
                        <div style={{ border: '8px solid rgba(255, 255, 255, 0.3)' }}>
                          <img src={prod.img} alt={prod.title} style={{ width: '100%', display: 'block', backgroundColor: 'white', maxHeight: '128px', objectFit: 'contain' }} />
                        </div>
                        <div style={{ color: '#f4b231', backgroundColor: 'rgba(255, 255, 255, 0.1)', textAlign: 'center', fontSize: '12px', fontWeight: '600', padding: '4px' }}>
                          {prod.title}
                        </div>
                      </div>
                    </a>
                  )
                })}
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      SetCategoryAction
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingCarousel))
