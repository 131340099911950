import React, { Component } from 'react'
import { Button, Spinner, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { 
    APISendMessage 
} from '../../apis/misc'
import './oem.css'

class OEMRequest extends Component {

    constructor() {
        super()
        this.state = {
            isSubmitting: false,
            isSubmitted: false
        }
    }

    async componentDidMount() {
    }

    onValueChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name + 'Error']: ""
        })
    }

    validateName = () => {
        if (this.state.fullName === "") {
            this.setState({
                fullNameError: 'Enter your name',
            })
            return false
        }
        return true
    }

    validateEmail = () => {
        if (this.state.email === "") {
            this.setState({
                emailError: 'Enter your email',
            })
            return false
        }
        //var pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        var pattern = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
        let bEmailOK = pattern.test(this.state.email)
        if (bEmailOK) {
            return true
        }
        this.setState({
            emailError: "Invalid email"
        })
        return false
    }

    validateEmpty = (name) => {
        const value = this.state[name]
        if (value === undefined || value === null || value.trim() === "") {
            this.setState({
                [name + 'Error']: `Enter your ${name}`
            })
            return false
        }
        return true
    }

    validatePassword = () => {
        if (this.state.password.length < 6) {
            this.setState({
                passwordError: 'Minimum 6 characters required',
            })
            return false
        }
        return true
    }

    validatePasswordRP = () => {
        if (this.state.password !== this.state.passwordRP) {
            this.setState({
                passwordRPError: 'Passwords do not match',
            })
            return false
        }
        return true
    }  

    renderItem = (inObj, isRequired = false, canEdit = true, isInput = true) => {
        return (
            <div className='oemItem'>
                <div className='oemItemTitle'>
                    {inObj.title}
                    {isRequired && <span style={{ color: 'red' }}>&nbsp;*</span>}
                </div>
                <div>
                    {
                        isInput ?
                            <input
                                style={{ width: '100%', fontSize: '13px' }}
                                value={this.state[inObj.name] || ""}
                                name={inObj.name}
                                placeholder={inObj.holder}
                                type={inObj.type}
                                disabled={!canEdit}
                                onChange={this.onValueChange} /> :
                            (
                                canEdit ?
                                    <textarea
                                        style={{ width: '100%', fontSize: '13px' }}
                                        defaultValue={this.state[inObj.name] || ""}
                                        name={inObj.name}
                                        placeholder={inObj.holder}
                                        onChange={this.onValueChange} /> :
                                    <span style={{ width: '100%', fontSize: '13px', color: 'lightgray' }}>
                                        {this.state[inObj.name]}
                                    </span>
                            )

                    }
                </div>
                <div>
                    <span className='oemError'>{this.state[inObj.name + 'Error']}</span>
                </div>
            </div>
        )
    }

    submitForm = async () => {
        let isValidated = true
        isValidated = this.validateEmail('email') && isValidated
        isValidated = this.validateEmpty('company') && isValidated
        isValidated = this.validateEmpty('contact') && isValidated
        isValidated = this.validateEmpty('phone') && isValidated
        isValidated = this.validateEmpty('message') && isValidated
        if (!isValidated) {
            return
        }
        this.setState({ isSubmitting: true })
        await this.props.APISendMessage(
            this.state.email,
            this.state.contact,
            "OEM",
            `Company: ${this.state.company}\nContact: ${this.state.contact}\nEmail: ${this.state.email}\nPhone: ${this.state.phone}\n${this.state.message}`,
             (success, error) => {
            if (success) {
                this.setState({
                    isSubmitting: false,
                    isSubmitted: true
                })
            } else {
                this.setState({
                    isSubmitting: false
                })
            }
          })
    }

    renderMessage = () => {
        return (
            <div className="oemMessage col-md-6 align-items-top">
                <p className='text-center' style={{ fontSize: '20px', fontWeight: 'bolder' }}>
                    OEM Service
                </p>
                <div className='text-left' style={{ fontSize: '16px', fontWeight: '500', }}>
                    <p>
                    McJ Tools' OEM Contract Manufacturing Service consistently delivers top-quality products and services to our customers. Our skilled workforce is dedicated to surpassing your expectations, employing meticulous planning and communication for each project to ensure timely delivery and optimal quality. At our facility, we handle the design, construction, and testing processes to guarantee functionality and proper placement.
                    </p>
                    <p>Key features of our service include:</p>
                    <ul>
                        <li>Flexible and Scalable Production: Take advantage of our CNC farm to meet your production needs.</li>
                        <li>Customization and Design: We offer individualized customization and design to align with our clients' specific needs and requirements.</li>
                        <li>Commitment to Quality: McJ Tools maintains a steadfast commitment to quality and continuous improvement.</li>
                        <li>Intellectual Property Protection: We prioritize the protection of your intellectual property.</li>
                        <li>Cost Advantages: Benefit from cost advantages through standardized flexible process procedures.</li>
                    </ul>
                    <p>
                        Makes Impossible Possible through hybrid efforts - Brain Power & Hard Working!
                    </p>
                </div>
            </div>
        )
    }

    renderSubmitted = () => {
        return (
            <div className="dealerMessage col-md-6 align-self-center text-center">
                <p className='text-center' style={{ fontSize: '24px', fontWeight: 'bolder', color: 'white' }}>
                    We are working hard to process your OEM request.
                </p>
            </div>
        )
    }

    renderForm = () => {
        let email = this.renderItem({ name: 'email', title: 'Email Address', type: 'text' }, true)
        let company = this.renderItem({ name: 'company', title: 'Company', type: 'text' }, true)
        let contact = this.renderItem({ name: 'contact', title: 'Contact Name', type: 'text' }, true)
        let phone = this.renderItem({ name: 'phone', title: 'Phone', type: 'text' }, true)
        let message = this.renderItem({ name: 'message', title: 'Message', type: 'text' }, true, true, false)
        
        return (
            <div className='oemForm col-md-6'>
                <div className='oemFormTitle'>
                    Request a Quote
                </div>
                {email}
                {company}
                {contact}
                {phone}
                {message}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {this.state.isSubmitting === true && <Spinner className='spinner-border-sm' animation='border' style={{ alignSelf: 'center', color: 'white', marginRight: '8px', width: '20px', height: '20px' }} />}
                    <Button className='oemSubmit mt-2' disabled={this.state.isSubmitting === true} variant='warning' onClick={this.submitForm}>
                        Submit
                    </Button>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="dealerRequest">
                <div className="row d-flex justify-content-between">
                    { this.renderMessage() }
                    { !this.state.isSubmitted && this.renderForm() }
                    { this.state.isSubmitted && this.renderSubmitted() }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    authed:state.authState.authed,
    authState: state.authState,
    userState: state.userState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APISendMessage
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OEMRequest)