import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from '../../../common/withRouter'

class MenuCart extends Component {

    countCartItems = () => {
        const {cart} = this.props.cartState
        if (typeof cart === 'undefined' || cart === null ) {
            return 0
        }
        let count = 0
        cart.forEach(item => {
            count += item.quantity
        });
        return count
    }

    render()
    {
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        const { authed } = this.props.authState
        let count = this.countCartItems()
        return (
            <a className="nav-link" href={authed ? `${region}/cart` : `${region}/signin`}>
                <i className="fa fa-shopping-cart me-2"> {count>0?count:""}</i>
                Cart
            </a>
        )
    }
}

const mapStateToProps = state => ({
    authState: state.authState,
    cartState: state.cartState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuCart))