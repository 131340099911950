import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {APIGetOrders, APIConfirmOrder} from '../../../apis/cart'
import './orders.css'
import {GetMonthName} from '../../../common/utils'
import MCJ from '../../../common/def';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap'

class UserOrders extends Component {

    constructor() {
        super()
        this.state = {
            status:MCJ.STATUS_LOADING,
        }
    }

    async componentDidMount() {
        await this.props.APIGetOrders()
        this.setState({ status:MCJ.STATUS_OK })
    }

    renderOrders = () => {
        const {orderList} = this.props.cartState
        const {allProductsById} = this.props.prodState
        const {system} = this.props.userState
        let imageUrlPrefix = MCJ.DefaultImgPrefix
        if (typeof system !== 'undefined' && system !== null) {
            imageUrlPrefix = system.imageUrlPrefix
        }

        let content = orderList.map(order => {            
            // Mock item details for test 
            let items = order.items
            if (typeof items === 'undefined' || items === null) {
                items = []
            }
            // render order detail
            let orderDetail = items.map(prodItem=>{
                let product = allProductsById[prodItem.productId]
                if(typeof product === 'undefined' || product === null) {
                    return null
                }
                return (
                    <div key={product.id} className='orderProdDetail'>
                        <img className='orderProdImg' alt='' src={imageUrlPrefix+product.thumbnail} />
                        <div style={{ fontSize: '14px' }}>
                            <Link to={`/product/${product.id}`}>
                                <span>{product.name}</span>
                            </Link>
                            <div>${prodItem.price}</div>
                            <div>Quantity:{prodItem.quantity}</div>
                        </div>
                    </div>
                )
            })
            
            let orderPlaceTime = new Date(order.createdAt)
            let strTime = `${GetMonthName(orderPlaceTime.getMonth())} ${orderPlaceTime.getDate()}, ${orderPlaceTime.getFullYear()}`
            return (
                <div key={order.id} className='orderItem'>
                    <div className='orderHead'>
                        <div className='headItem'>
                            <span>DATE</span>
                            <span className='headItemContent'>{strTime}</span>
                        </div>
                        <div className='headItem'>
                            <span>TOTAL</span>
                            <span className='headItemContent'>US${order.summary?.total}</span>
                        </div>
                        {/* <div className='headItem'>
                            <span>SHIP INFORMATION</span>
                        </div> */}
                        <div className='headItem'>
                            <span>ORDER # {order.id}</span>
                            <span className='headItemContent'><Link to={`/order/${order.id}`}>Order Details</Link></span>
                        </div>
                    </div>
                    <div className='orderProds'>
                        {orderDetail}
                    </div>
                    <div className='orderBottom'> 
                        <div>Status:{order.status}</div>
                        {/* {item.status==='CREATED'?(<Link to={`/order/${item.id}`}>
                            <Button variant='warning'>Make payment</Button>
                        </Link>
                        ):null}
                        {item.status==='CREATED'?<Button variant='secondary' onClick={()=>{
                            this.props.APIConfirmOrder(false)
                        }}>Cancel This Order</Button>:null}                     */}
                    </div>
                </div>
            )
        })
        return content
    }


    render(){
        let content = this.renderOrders()
        if (content.length === 0) {
            if (this.state.status === MCJ.STATUS_LOADING) {
                content = (
                    <div style={{ textAlign: 'center' }}>
                        <Spinner animation='border' style={{ marginTop: '32px' }} />
                    </div>
                )
            } else {
                content = (
                    <div>
                        <h4 style={{ marginTop: '32px', color: 'GrayText' }}>You don't have any order yet.</h4>    
                    </div>
                )
            }
        }
        return (
            <div>
                <h2>Your Orders</h2>
                <hr/>
                {content}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    cartState:state.cartState,
    prodState:state.prodState,
    userState:state.userState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetOrders,
        APIConfirmOrder
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserOrders)