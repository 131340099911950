import React from 'react'
import { connect } from 'react-redux'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PayOrderAction, APIGetCart, APIGetOrders, APIConfirmOrder } from '../../../apis/cart'
import { bindActionCreators } from 'redux'
import MCJ from '../../../common/def'
import { Badge, Button } from 'react-bootstrap'
import './orderDetail.css'

const OrderPayment = (props) => {

    const elements = useElements()
    const stripe = useStripe()

    const confirmPayment = async () => {
        try {
            props.PayOrderAction(MCJ.PAYMENT_PAYING)
            let {error, paymentIntent} = await stripe.confirmPayment({
                elements,
                redirect:'if_required'
            })
            console.log('confirmPayment error=', error)
            console.log('confirmPayment intent', paymentIntent)
            if (paymentIntent.status === 'succeeded') {
                props.APIConfirmOrder(true)
                // props.PayOrderAction(MCJ.PAYMENT_OK)
                // props.APIGetOrders()
                setTimeout(()=>{
                    // Get cart after 500 ms
                    props.APIGetCart()
                    props.APIGetOrders()
                }, 500)
            } else {
                console.log('pay failed! result=', error)
                props.PayOrderAction(MCJ.PAYMENT_ERR)    
            }
        } catch(err) {
            console.log('confirmPayment err', err)
            props.PayOrderAction(MCJ.PAYMENT_ERR)
        }
    }

    let processing = <div><Badge bg='primary'><h3>Processing payment</h3></Badge></div>

    let payOK = <div><Badge bg='success'><h3>Payment Succeed</h3></Badge></div>

    let payInit = <form>
        <PaymentElement />
        <div className='payButton'>
            <Button onClick={confirmPayment}>Submit</Button>
        </div>
    </form>

    let payErr = <div><Badge bg='danger'><h3>Payment failed</h3></Badge></div>  

    let content = null

    let cardStyle = ""

    const paymentStatus = props.cartState.paymentStatus
    if (typeof paymentStatus === 'undefined' || paymentStatus === null) {
        //content = payInit
    } else {
        switch(paymentStatus) {
            case MCJ.PAYMENT_INIT:
                //content = payInit
                break
            case MCJ.PAYMENT_PAYING:
                content = processing
                cardStyle = "hideMe"
                break
            case MCJ.PAYMENT_OK:
                content = payOK
                cardStyle = "hideMe"
                break
            case MCJ.PAYMENT_ERR:
                content = payErr
                break
            default:
                content = null
                //content = payInit
        }
    }

    

    return <>
        <div>
            <div className={cardStyle}>
                {payInit}
            </div>
        </div>
        <div>
            {content}
        </div>
    </> 
}

const mapStateToProps = state => ({
    cartState:state.cartState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        PayOrderAction,
        APIGetCart,
        APIGetOrders,
        APIConfirmOrder
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPayment)