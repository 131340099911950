import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { APIGetProductDetail } from '../../apis/products'
import { withRouter } from '../../common/withRouter'

class CleaningMaintenance extends Component {

    constructor() {
        super()
        this.state = {
            list: [
                {
                    "img": "https://mcjtools-prod-assets.s3.amazonaws.com/images/rifle-Bore-Brushes/rifle_bp__thumbnail.jpg",
                    "title": 'RIFLE BRONZE BORE BRUSH',
                    "desc": 'The number of bristles are maximized for the caliber.',
                    "url": "product/18",
                    "id": 18
                },
                {
                    "img": "https://mcjtools-prod-assets.s3.amazonaws.com/images/cb/chamber-brush-22cal-10-thumbnail.jpg",
                    "title": "BRONZE CHAMBER BRUSHE",
                    "desc": 'Tapered bronze bristles scrub the chamber, neck and shoulder.',
                    "url": "product/253",
                    "id": 253
                },
                {
                    "img": "https://mcjtools-prod-assets.s3.amazonaws.com/images/bs/bore-snake9mm-375-38-thumbnail.jpg",
                    "title": 'BORESNAKE 9MM',
                    "desc": 'Strong and Reusable: Sturdy, machine washable.',
                    "url": "product/249",
                    "id": 249
                },
                {
                    "img": "https://mcjtools-prod-assets.s3.amazonaws.com/images/bs/bore-snake-223-22-5_56-thumbnail.jpg",
                    "title": 'BORESNAKE 5.56MM',
                    "desc": 'Strong and Reusable: Sturdy, machine washable.',
                    "url": "product/249",
                    "id": 249
                },
                {
                    "img": "https://mcjtools-prod-assets.s3.amazonaws.com/images/bs/bore-snake-308-7_62-thumbnail.jpg",
                    "title": 'BORESNAKE 7.62MM',
                    "desc": 'Strong and Reusable: Sturdy, machine washable.',
                    "url": "product/251",
                    "id": 251
                },
                {
                    "img": "https://mcjtools-prod-assets.s3.amazonaws.com/images/pistol-bore-brush-38/pb38_thumbnail.jpg",
                    "title": 'PISTOL BRONZE BORE BRUSH ',
                    "desc": 'The number of bristles are maximized for the caliber. Bristles are made of high quality bronze.',
                    "url": "product/54",
                    "id": 54
                }
            ].sort(() => 0.5 - Math.random()).splice(3)
        }
    }

    componentDidMount() {
        this.state.list.forEach((item) => {
            this.props.APIGetProductDetail(item.id)
        })
    }

    render() {
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        const { productDetailsById } = this.props
        return (
            <main id="bestsellers" className="text-center">
                <div className="container">
                    <div className='section-title'>
                        <h2>Cleaning & Maintenance</h2>
                    </div>
                </div>
                <div className="container d-flex justify-content-between mt-5">
                    <div className="container overflow-visible">
                        <div className="row">
                            {
                                this.state.list.map((item, i) => {
                                    let price = undefined
                                    let product = productDetailsById[item.id]
                                    if (typeof product !== 'undefined' && product !== null) {
                                        price = product.price
                                    }
                                    return (
                                    <div key={`${i}.${item.name}`} className="col-lg-4 col-md-6 col-sm-6" style={{ marginTop: '24px'}}>
                                        <div className="card prod-card h-100 pb-3 shadow">
                                            <img src={item.img} className="card-img-top img-fluid" alt={item.name} />
                                            <div className="card-body">
                                                <h4 className="card-title">{item.title}</h4>
                                                <p className="card-text">
                                                    {item.desc}
                                                </p>
                                            </div>
                                            { (region !== "/au" && region !== "/nz") && (
                                                <div className="card-footer card-bottom d-flex justify-content-between">
                                                    <h3 className="price">${price !== undefined ? price : '--.--'}</h3>
                                                    <a href={`${region}/${item.url}`} className="btn btn-warning">
                                                        <i className="fa fa-shopping-cart me-2"></i>
                                                        Buy Now
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}


const mapStateToProps = state => ({
  productDetailsById: state.prodState.productDetailsById
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      APIGetProductDetail
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CleaningMaintenance))
