const Image = ({ title, largeImage, smallImage }) => {
  return (
    <div className='gallery-item'>
      <div className='hover-bg'>
        {' '}
        <a
          href={largeImage}
          title={title}
          data-lightbox-gallery='gallery1'
        >
          <div className='hover-text'>
            <h4>{title}</h4>
          </div>
          <img
            src={smallImage}
            style={{ maxWidth: '100%', height: 'auto' }}
            alt={title}
          />{' '}
        </a>{' '}
      </div>
    </div>
  )
}

export const Gallery = (props) => {
  const group1 = [
    {
      "title": "McJ Tools Reloading Ultimate Lock Rings",
      "largeImage": "/images/gallery/reloading_1_large.jpg",
      "smallImage": "/images/gallery/reloading_1.jpg"
    },
    {
      "title": "McJ Tools Reloading Bronze Bore Brush",
      "largeImage": "/images/gallery/reloading_2_large.jpg",
      "smallImage": "/images/gallery/reloading_2.jpg"
    },
    {
      "title": "McJ Tools Reloading Decapping Pin",
      "largeImage": "/images/gallery/reloading_3_large.jpg",
      "smallImage": "/images/gallery/reloading_3.jpg"
    }
  ]
  const group2 = [
    {
      "title": "McJ Tools 3/4 Inch HSS M2 Drill Bit Hole Saw",
      "largeImage": "/images/gallery/holesaw_1_large.jpg",
      "smallImage": "/images/gallery/holesaw_1.jpg"
    },
    {
      "title": "McJ Tools 3/4 Inch HSS M2 Drill Bit Hole Saw",
      "largeImage": "/images/gallery/holesaw_2_large.jpg",
      "smallImage": "/images/gallery/holesaw_2.jpg"
    },
    {
      "title": "McJ Tools 1-3/8 Inch TCT Hole Saw",
      "largeImage": "/images/gallery/holesaw_3_large.jpg",
      "smallImage": "/images/gallery/holesaw_3.jpg"
    }
  ]
  return (
    <section id="gallery" className="text-center">
      <div className='container'>
        <div className='section-title'>
          <h2>Gallery</h2>
          <p>
            Reloading Tools
          </p>
        </div>
        <div className='row'>
            {
              group1.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-6 col-sm-6 col-md-4 col-lg-4'>
                  <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
                </div>
              ))
            }
        </div>
      </div>
      <div className='container'>
        <div className='section-title'>
          <p>
            Metal Hole Saws
          </p>
        </div>
        <div className='row'>
            {
              group2.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-6 col-sm-6 col-md-4 col-lg-4'>
                  <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
                </div>
              ))
            }
        </div>
      </div>
    </section>
  )
}
