/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import Logo from '../../../common/logo'
import MenuAccount from './menuAccount'
import MenuCart from './menuCart'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SetCategoryAction } from '../../../apis/products'
import { Link } from 'react-router-dom';
import { withRouter } from '../../../common/withRouter'
import FlagUS from "../../../assert/flag_us.svg"
import FlagAU from "../../../assert/flag_au.svg"
import FlagNZ from "../../../assert/flag_nz.svg"

class NavBar extends Component {
    
    constructor(){
        super()
        this.state = {
            currCountryCode: localStorage.getItem('kCurrCountryCode') || 'us'
        }
    }

    componentDidMount() {
        this.checkAndRedirectCountry()
        // Close menu after clicking
        const navLinks = document.querySelectorAll('.nav-item:not(.dropdown)')
        const menuToggle = document.getElementById('navbarNav')
        navLinks.forEach((l) => {
            l.addEventListener('click', () => {
                if (menuToggle.classList.contains('show')) {
                    menuToggle.classList.remove('show')
                }
            })
        })
    }

    checkAndRedirectCountry() {
        let path = window.location.pathname
        const countryCode = path.includes('/au') ? 'au' : (path.includes('/nz') ? 'nz' : 'us')
        if (this.state.currCountryCode !== countryCode) {
            this.handleSelectCountry(this.state.currCountryCode)
        }
    }

    handleSelectCountry(countryCode) {
        localStorage.setItem('kCurrCountryCode', countryCode);
        this.setState({ currCountryCode: countryCode })

        let path = window.location.pathname
        if (countryCode === 'us') {
            path = path.replace(/\/(au|nz)/, '');
        } else {
            if (path.startsWith('/au') || path.startsWith('/nz')) {
                path = path.replace(/(au|nz)/, `${countryCode}`)
            } else {
                path = `/${countryCode}${path}`
            }
        }
        if (window.location.pathname !== path) {
            window.location.pathname = path
        }
    }

    render() {
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        const { categories, currCategoryId } = this.props.prodState
        return (
            <div className='fixed-top'>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <div className='container'>
                        <Logo />
                        <div className='navbar-header'>
                            <button
                                type="button"
                                className="navbar-toggler"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarNav"
                                aria-controls="navbarNav"
                                aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <div id="navbarNav" className="collapse navbar-collapse justify-content-end">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <a className="nav-link" href={`${region}/#top`}>
                                        Home
                                    </a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        href="#menu"
                                        id="navbarDropdown"
                                        className="nav-link dropdown-toggle"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                        {`${categories?.filter(c => c.id === currCategoryId)[0]?.name || "Products"}`}
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        {
                                            (typeof categories !== 'undefined' && categories !== null) && categories.map(category => {
                                                return (
                                                    <li key={category.id}
                                                        className="nav-item active">
                                                        <Link className='dropdown-item' to={`${region}/products?category=${category.id}`} onClick={()=> {
                                                            this.props.SetCategoryAction(category.id)
                                                            if (window.innerWidth < 992) {
                                                                document.querySelector('.navbar-toggler').click()
                                                            }
                                                        }}>
                                                            {category.name}
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className='nav-link' to={`${region}/oem`}>
                                        OEM
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className='nav-link' to={`${region}/dealer`}>
                                        Dealer
                                    </Link>
                                </li>
                                { (region !== "/au" && region !== "/nz") && (
                                    <li className="nav-item">
                                        <MenuAccount />
                                    </li>
                                )}
                                { (region !== "/au" && region !== "/nz") && (
                                    <li className="nav-item">
                                        <MenuCart />
                                    </li>
                                )}
                                <li className="nav-item dropdown">
                                    <a href="#country"
                                        className="nav-link dropdown-toggle"
                                        id="navCountryDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                        { this.state.currCountryCode === 'us' && <img src={FlagUS} alt="US Flag" style={{ width: '20px', margin: '4px', borderColor: 'white', outline: '1px solid #CCC' }} />}
                                        { this.state.currCountryCode === 'au' && <img src={FlagAU} alt="AU Flag" style={{ width: '20px', margin: '4px', borderColor: 'white', outline: '1px solid #CCC' }} />}
                                        { this.state.currCountryCode === 'nz' && <img src={FlagNZ} alt="NZ Flag" style={{ width: '20px', margin: '4px', borderColor: 'white', outline: '1px solid #CCC' }} />}
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navCountryDropdown">
                                        {this.state.currCountryCode !== 'us' && (
                                            <li onClick={() => this.handleSelectCountry('us')}>
                                                <a className="dropdown-item" style={{
                                                    cursor: 'pointer'
                                                }}>
                                                    <img src={FlagUS} alt="US Flag" style={{ width: '20px', marginRight: '8px' }} />USA
                                                </a>
                                            </li>
                                        )}
                                        {this.state.currCountryCode !== 'au' && (
                                            <li onClick={() => this.handleSelectCountry('au')}>
                                                <a className="dropdown-item" style={{
                                                    cursor: 'pointer'
                                                }}>
                                                    <img src={FlagAU} alt="Australia Flag" style={{ width: '20px', marginRight: '8px' }} />Australia
                                                </a>
                                            </li>
                                        )}
                                        {this.state.currCountryCode !== 'nz' && (
                                            <li onClick={() => this.handleSelectCountry('nz')}>
                                                <a className="dropdown-item" style={{
                                                    cursor: 'pointer'
                                                }}>
                                                    <img src={FlagNZ} alt="New Zealand Flag" style={{ width: '20px', marginRight: '8px' }} />New Zealand
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    prodState: state.prodState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        SetCategoryAction
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar))