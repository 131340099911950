import React, { Component } from 'react'
import { Button, Spinner, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { 
    APIGetUserInfo,
    APISaveUserInfo 
} from '../../apis/user'
import { APISignUp } from '../../apis/auth'
import './dealer.css'
import { AddressDropDown } from '../../common/addressDropDown'

class DealerRequest extends Component {

    constructor() {
        super()
        this.state = {
            isSubmitting: false,
            showConfirm: false,
            showProcessing: true
        }
    }

    async componentDidMount() {
        await this.props.APIGetUserInfo()
        const { userInfo } = this.props.userState
        if (typeof userInfo !== 'undefined' && userInfo !== null) {
            this.setState({
                email: userInfo.contactEmail,
                fullName: userInfo.fullName,
                phone: userInfo.phone,
                fax: userInfo.fax,
                address: userInfo.address,
                city: userInfo.city,
                state: userInfo.state,
                country: userInfo.country,
                postal: userInfo.postal,
                company: userInfo.company,
                note: userInfo.note,
                ffLicense: userInfo.ffLicense,
                taxId: userInfo.taxId,
                dealerStatus: userInfo.dealerStatus,
                message: userInfo.message,
                isSubmitting: false
            })
        }
        const currCountry = this.state['country']
        if (typeof currCountry === 'undefined' || currCountry === null || currCountry === "") {
            this.onValueChange({ target: { name: 'country', value: 'US' } })
        }
    }

    onValueChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name + 'Error']: ""
        })
    }

    validateName = () => {
        if (this.state.fullName === "") {
            this.setState({
                fullNameError: 'Enter your name',
            })
            return false
        }
        return true
    }

    validateEmail = () => {
        if (this.state.email === "") {
            this.setState({
                emailError: 'Enter your email',
            })
            return false
        }
        //var pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        var pattern = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
        let bEmailOK = pattern.test(this.state.email)
        if (bEmailOK) {
            return true
        }
        this.setState({
            emailError: "Invalid email"
        })
        return false
    }

    validateEmpty = (name) => {
        const value = this.state[name]
        if (value === undefined || value === null || value.trim() === "") {
            this.setState({
                [name + 'Error']: `Enter your ${name}`
            })
            return false
        }
        return true
    }

    validatePassword = () => {
        if (this.state.password.length < 6) {
            this.setState({
                passwordError: 'Minimum 6 characters required',
            })
            return false
        }
        return true
    }

    validatePasswordRP = () => {
        if (this.state.password !== this.state.passwordRP) {
            this.setState({
                passwordRPError: 'Passwords do not match',
            })
            return false
        }
        return true
    }  

    renderItem = (inObj, isRequired = false, canEdit = true, isInput = true) => {
        return (
            <div className='dealerItem'>
                <div className='dealerItemTitle'>
                    {inObj.title}
                    {isRequired && <span style={{ color: 'red' }}>&nbsp;*</span>}
                </div>
                <div>
                    {
                        isInput ?
                            <input
                                style={{ width: '100%', fontSize: '13px' }}
                                value={this.state[inObj.name] || ""}
                                name={inObj.name}
                                placeholder={inObj.holder}
                                type={inObj.type}
                                disabled={!canEdit}
                                onChange={this.onValueChange} /> :
                            (
                                canEdit ?
                                    <textarea
                                        style={{ width: '100%', fontSize: '13px' }}
                                        defaultValue={this.state[inObj.name] || ""}
                                        name={inObj.name}
                                        placeholder={inObj.holder}
                                        onChange={this.onValueChange} /> :
                                    <span style={{ width: '100%', fontSize: '13px', color: 'lightgray' }}>
                                        {this.state[inObj.name]}
                                    </span>
                            )

                    }
                </div>
                <div>
                    <span className='dealerError'>{this.state[inObj.name + 'Error']}</span>
                </div>
            </div>
        )
    }

    submitForm = async () => {
        let isValidated = true
        isValidated = this.validateEmail('email') && isValidated
        if (!this.props.authed) {
            isValidated = this.validatePassword() && isValidated
            isValidated = this.validatePasswordRP() && isValidated
        }
        isValidated = this.validateEmpty('company') && isValidated
        isValidated = this.validateName() && isValidated
        isValidated = this.validateEmpty('phone') && isValidated
        isValidated = this.validateEmpty('address') && isValidated
        isValidated = this.validateEmpty('city') && isValidated
        isValidated = this.validateEmpty('state') && isValidated
        isValidated = this.validateEmpty('country') && isValidated
        isValidated = this.validateEmpty('postal') && isValidated
        isValidated = this.validateEmpty('note') && isValidated
        if (!isValidated) {
            return
        }
        const reqObj = {
            email: this.state.email,
            password:this.state.password,
            contactEmail: this.state.email,
            company: this.state.company,
            fullName: this.state.fullName,
            phone: this.state.phone,
            fax: this.state.fax,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            postal: this.state.postal,
            ffLicense: this.state.ffLicense,
            taxId: this.state.taxId,
            note: this.state.note,
            dealer: true
        }
        this.setState({ isSubmitting: true })
        if (this.props.authed) {
            await this.props.APISaveUserInfo(reqObj)
            this.setState({
                isSubmitting: false,
                showConfirm: (this.props.userState.error || "").length === 0,
                dealerStatus: (this.props.userState.userInfo || {}).dealerStatus
            })
        } else {
            await this.props.APISignUp(reqObj)
            this.setState({
                isSubmitting: false,
                showConfirm: (this.props.authState.error || "").length === 0,
                dealerStatus: (this.props.userState.userInfo || {}).dealerStatus
            })
        }
    }

    renderMessage = () => {
        return (
            <div className="dealerMessage col-md-6 align-items-top">
                <p className='text-center' style={{ fontSize: '20px', fontWeight: 'bolder' }}>
                    Dear Prospective Dealer
                </p>
                <p className='text-center' style={{ fontSize: '16px', fontWeight: '500' }}>
                    Thank you for your interest in becoming a McJTools® dealer. This is an excellent chance for you to tap into our products and have the consumer purchase directly from you. Having the consumer purchase directly from our dealers is most often the quickest, least expensive and most convenient means to obtaining our products.
                </p>
                <p className='text-center' style={{ fontSize: '16px', fontWeight: '500', }}>
                    We may contact your business to verify certain information. Once you dealership is confirmed, you will be eligible for special pricing and exact weight shipments. You will also be added to the Recommended Dealer Network on our website. This will enable you to provide your customers with McJTools® products at a great price. What are the Requirements for becoming a McJTools® dealer? If you are interested in becoming a McJTools® dealer, please fill out and submit the form below.
                </p>
            </div>
        )
    }

    userIsDealer = () => {
        const { userInfo } = this.props.userState
        if (typeof userInfo !== 'undefined' && userInfo !== null) {
            return userInfo.dealer === true || userInfo.dealerStatus === "Completed"
        }
        return false
    }

    needsToShowProcessing = () => {
        const { userInfo } = this.props.userState
        if (typeof userInfo !== 'undefined' && userInfo !== null) {
            return this.state.showProcessing &&
                userInfo.dealer === false &&
                userInfo.dealerStatus !== "" &&
                userInfo.dealerStatus !== "Completed"
        }
        return false
    }

    renderProcessing = () => {
        return (
            <div className="dealerMessage col-md-6 align-self-center text-center">
                <p className='text-center' style={{ fontSize: '24px', fontWeight: 'bolder', color: 'white' }}>
                    We are working hard to process your dealership request.
                </p>
                <Button className='dealerShowForm mt-2' variant='warning' onClick={() => this.setState({showProcessing: false })}>
                    Update Information
                </Button>
            </div>
        )
    }

    renderDealer = () => {
        return (
            <div className="dealerMessage col-md-6 align-self-center">
                <p className='text-center' style={{ fontSize: '24px', fontWeight: 'bolder', color: 'white' }}>
                    You are authorized McJ Tools Dealer.
                </p>
            </div>
        )
    }

    renderForm = () => {
        let email = this.renderItem({ name: 'email', title: 'Email Address', type: 'text' }, true, !this.props.authed)
        let password = this.renderItem({ name: 'password', title: 'Password', holder: 'At least 6 characters', type: 'password' }, true)
        let passwordRP = this.renderItem({ name: 'passwordRP', title: 'Confirm Password', holder: 'At least 6 characters', type: 'password' }, true)
        let company = this.renderItem({ name: 'company', title: 'Company', type: 'text' }, true)
        let ffLicense = this.renderItem({ name: 'ffLicense', title: 'FF License #', holder: '1-22-333-44-5X-66666', type: 'text' })
        let taxId = this.renderItem({ name: 'taxId', title: 'Tax ID #', type: 'text' })
        let contact = this.renderItem({ name: 'fullName', title: 'Contact Name', type: 'text' }, true)
        let phone = this.renderItem({ name: 'phone', title: 'Phone', type: 'text' }, true)
        let fax = this.renderItem({ name: 'fax', title: 'Fax', type: 'text' })
        let address = this.renderItem({ name: 'address', title: 'Street Address', type: 'text' }, true)
        let city = this.renderItem({ name: 'city', title: 'City', type: 'text' }, true)
        let postal = this.renderItem({ name: 'postal', title: 'Postal', type: 'text' }, true)
        let state = this.renderItem({ name: 'state', title: 'State', type: 'text' }, true)
        let country = this.renderItem({ name: 'country', title: 'Country', type: 'text' }, true)
        let note = this.renderItem({ name: 'note', title: 'Note', type: 'text' }, true, true, false)
        let dealerStatus = this.renderItem({ name: 'dealerStatus', title: 'Request Status', type: 'text' }, false, false, false)
        let message = this.renderItem({ name: 'message', title: 'Admin Message', type: 'text' }, false, false, false)
        const system = this.props.userState.system
        if (typeof system !== 'undefined' && system !== null) {
            let currCountry = this.state['country'] || ""
            country = (
                <div className='dealerItem'>
                    <div style={{ display: 'flex', direction: 'row' }}>
                        <div className='dealerItemTitle'>Country</div>
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </div>
                    <div style={{ width: '100%' }}>
                        <AddressDropDown choiceKey='country' choiceValue={currCountry} choices={system.countries} onClick={(e) => {
                            this.onValueChange(e)
                        }} />
                    </div>
                    <div>
                        <span className='dealerError'>{this.state['countryError']}</span>
                    </div>
                </div>
            )
            const states = (system.countries.find(c => c.abbrev.toLowerCase() === currCountry.toLowerCase()) || { states: [] }).states
            let currState = this.state['state']
            state = (
                <div className='dealerItem'>
                    <div style={{ display: 'flex', direction: 'row' }}>
                        <div className='dealerItemTitle'>State</div>
                        <span style={{ color: 'red' }}>&nbsp;*</span>
                    </div>
                    <div style={{ width: '100%' }}>
                        <AddressDropDown choiceKey='state' choiceValue={currState} choices={states} onClick={(e) => {
                            this.onValueChange(e)
                        }} />
                    </div>
                    <div>
                        <span className='dealerError'>{this.state['stateError']}</span>
                    </div>
                </div>
            )
        }
        return (
            <div className='dealerForm col-md-6'>
                <div className='dealerFormTitle'>
                    Dealer Request Form
                </div>
                {email}
                {!this.props.authed && password}
                {!this.props.authed && passwordRP}
                {company}
                {ffLicense}
                {taxId}
                {contact}
                <div style={{ display: 'flex' }}>
                    <div className='col-6'>{phone}</div>
                    <div className='col-6'>{fax}</div>
                </div>
                {address}
                <div style={{ display: 'flex' }}>
                    <div className='col-8'>{city}</div>
                    <div className='col-4'>{postal}</div>
                </div>
                {country}
                {state}
                {note}
                {(this.state["dealerStatus"] || "").length > 0 ? dealerStatus : null}
                {(this.state["message"] || "").length > 0 ? message : null}
                <div className='dealerItem'>
                    <span style={{ color: 'red', fontSize: '13px', wordWrap: 'break-word' }}>{this.props.authState.error}</span>
                </div>
                <div className='dealerItem'>
                    <span style={{ color: 'red', fontSize: '13px', wordWrap: 'break-word' }}>{this.props.userState.error}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {this.state.isSubmitting === true && <Spinner className='spinner-border-sm' animation='border' style={{ alignSelf: 'center', color: 'white', marginRight: '8px', width: '20px', height: '20px' }} />}
                    <Button className='dealerSubmit mt-2' disabled={this.state.isSubmitting === true} variant='warning' onClick={this.submitForm}>
                        {
                            (this.state.dealerStatus || "") === "" ? "Submit" : "Update"
                        }
                    </Button>
                </div>
                {this.renderConfirmModal()}
            </div>
        )
    }

    renderConfirmModal = (theProduct) => {
        return (
            <Modal centered size="sm" show={this.state.showConfirm} onHide={() => this.setState({ showConfirm:false })} >
                <Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '20px', fontWeight: 'bolder' }}>
                        Dealer Request Submitted
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='cartDialogBody'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='cartDialogFoot'>
                    <Button variant="warning" onClick={() => this.setState({ showConfirm:false })}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        return (
            <div className="dealerRequest">
                <div className="row d-flex justify-content-between">
                    { this.renderMessage() }
                    {
                        this.userIsDealer() ?
                            this.renderDealer() : (
                                this.needsToShowProcessing() ?
                                    this.renderProcessing() :
                                    this.renderForm()
                            )
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    authed:state.authState.authed,
    authState: state.authState,
    userState: state.userState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetUserInfo,
        APISaveUserInfo,
        APISignUp
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DealerRequest)