import {
    getCategories,
    getProductsByCategory,
    setCurrCategoryId,
    getProductDetail,
    getProducts
} from "../store/prodSlice"
import { HandleResponseError } from "./api"

export function APIGetCategories() {
    return async dispatch => {
        try {
            let rsp = await fetch('/api/anonymous/product/category')
            if (rsp.status === 200) {
                let data = (await rsp.json()).data
                dispatch(getCategories({
                    data
                }))
            }
        } catch(err) {
            console.log('catch err in GetCategories')
        }
    }
}

export function APIGetProductsByCategory(categoryId) {
    return async dispatch => {
        try {
            let rsp = await fetch(`/api/anonymous/product?categoryId=${categoryId}`)
            if (rsp.status === 200) {
                let data = (await rsp.json()).data
                dispatch(getProductsByCategory({
                    data,
                    categoryId
                }))
            }
        } catch(err) {
            console.log('catch err in GetProductsByCategory')
        }
    }
}

export function SetCategoryAction(category) {
    return dispatch => {
        dispatch(setCurrCategoryId(category))
    }
}

export function APIGetProducts() {
    return async dispatch => {
        try {
            let rsp = await fetch('/api/anonymous/product')
            if (rsp.status === 200) {
                let data = (await rsp.json()).data
                dispatch(getProducts({
                    data
                }))
            }
        } catch(err) {
            console.log('catch err in get product' + err)
        }
    }
}

export function APIGetProductDetail(productId) {
    return async dispatch => {
        try {
            let rsp = await fetch(`/api/anonymous/product/${productId}`)
            HandleResponseError(rsp)
            if (rsp.status === 200) {
                let data = (await rsp.json()).data
                dispatch(getProductDetail({
                    ret:true,
                    data
                }))
            } else {
                dispatch(getProductDetail({
                    ret:false
                }))
            }
        } catch(err) {
            console.log('catch err in get product detail')
            dispatch(getProductDetail({
                ret:false
            }))
        }
    }
}