import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { APIGetProductDetail } from '../../apis/products'
import { withRouter } from '../../common/withRouter'

class BestSellers extends Component {

    constructor() {
        super()
        this.state = {
            list: [
                {
                    "img": "/images/bestsellers/bestsellers_1.jpg",
                    "title": 'DIE LOCK RING 7/8X14 PREMIUM STEEL CONSTRUCTED 6/PACK',
                    "desc": 'Premium steel-constructed, our team of engineers has spent the past 20 years refining the manufacturing process to create the highest quality firearm tools on the market.',
                    "url": "product/2",
                    "id": 2
                },
                {
                    "img": "/images/bestsellers/bestsellers_2.jpg",
                    "title": "PRIMER POCKET UNIFORMER SET (SMALL AND LARGE)",
                    "desc": 'The perfect solution for ensuring uniform primer pocket depth in your reloaded cartridge cases so that loads ignite the same each time.',
                    "url": "product/1",
                    "id": 1
                },
                {
                    "img": "/images/bestsellers/bestsellers_3.jpg",
                    "title": 'CHAMFER/DEBURRING TOOL 0.17-0.45 CAL',
                    "desc": 'Multifunctional piece of equipment. High precision and tough durability. Can be used by hand or attached to a DBT base.',
                    "url": "product/38",
                    "id": 38
                },
                {
                    "img": "/images/bestsellers/bestsellers_4.jpg",
                    "title": 'PRIMER POCKET CLEANER SET (SMALL AND LARGE)',
                    "desc": 'The perfect solution for quickly cleaning and removing fouling/ carbon residue from primer pockets, preparing cases for more consistent, positive primer seating.',
                    "url": "product/21",
                    "id": 21
                },
                {
                    "img": "/images/bestsellers/bestsellers_5.jpg",
                    "title": '3/4 Inch HSS M2 Drill Bit Hole Saw for Metal, Steel, Iron, Alloy',
                    "desc": 'Work well with abrasive materials like high density resin and metal plates',
                    "url": "product/233",
                    "id": 233
                },
                {
                    "img": "/images/bestsellers/bestsellers_6.jpg",
                    "title": '3-BLADE DEBURR TOOL',
                    "desc": 'Designed to remove the burrs from the outside of a case mouth.',
                    "url": "product/9",
                    "id": 9
                }
            ].sort(() => 0.5 - Math.random()).splice(3)
        }
    }

    componentDidMount() {
        this.state.list.forEach((item) => {
            this.props.APIGetProductDetail(item.id)
        })
    }

    render() {
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        const { productDetailsById } = this.props
        return (
            <main id="bestsellers" className="text-center">
                <div className="container">
                    <div className='section-title'>
                        <h2>Best Sellers</h2>
                    </div>
                </div>
                <div className="container d-flex justify-content-between mt-5">
                    <div className="container overflow-visible">
                        <div className="row">
                            {
                                this.state.list.map((item, i) => {
                                    let price = undefined
                                    let product = productDetailsById[item.id]
                                    if (typeof product !== 'undefined' && product !== null) {
                                        price = product.price
                                    }
                                    return (
                                    <div key={`${i}.${item.name}`} className="col-lg-4 col-md-6 col-sm-6" style={{ marginTop: '24px'}}>
                                        <div className="card prod-card h-100 pb-3 shadow">
                                            <img src={item.img} className="card-img-top img-fluid" alt={item.name} />
                                            <div className="card-body">
                                                <h4 className="card-title">{item.title}</h4>
                                                <p className="card-text">
                                                    {item.desc}
                                                </p>
                                            </div>
                                            { (region !== "/au" && region !== "/nz") && (
                                                <div className="card-footer card-bottom d-flex justify-content-between">
                                                    <h3 className="price">${price !== undefined ? price : '--.--'}</h3>
                                                    <a href={`${region}/${item.url}`} className="btn btn-warning">
                                                        <i className="fa fa-shopping-cart me-2"></i>
                                                        Buy Now
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}


const mapStateToProps = state => ({
  productDetailsById: state.prodState.productDetailsById
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      APIGetProductDetail
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BestSellers))
