export const Team = (props) => {
  const jsonData = [
    {
      "img": "/images/team/01.jpg",
      "name": "Nick Arifi",
      "job": "Director",
      "email": "nick.arifi@mcjtools.com",
      "phone": "(302) 203-9688"
    },
    {
      "img": "/images/team/02.jpg",
      "name": "Janelle Diel",
      "job": "Customer Account Manager",
      "email": "janelle.diel@mcjtools.com",
      "phone": "(302) 203-9713"
    },
    {
      "img": "/images/team/04.jpg",
      "name": "Vesa Hasani",
      "job": "Customer Account Manager",
      "email": "vesa.hasani@mcjtools.com",
      "phone": "(302) 203-9571"
    },
    {
      "img": "/images/team/05.jpg",
      "name": "Dijon Gjinovci",
      "job": "Customer Account Manager",
      "email": "dijon.gjinovci@mcjtools.com",
      "phone": "(302) 203-9569"
    },
    // {
    //   "img": "/images/team/03.jpg",
    //   "name": "Ericka Estrellanes",
    //   "job": "Customer Account Manager",
    //   "email": "ericka.estrellanes@mcjtools.com"
    // },
  ]
  
  return (
    <section id='team' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Marketing & Customer Service</h2>
        </div>
        <div className='row mt-5'>
          {
            jsonData.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-lg-3 col-md-4 col-sm-6 team'>
                <div className='thumbnail'>
                  {' '}
                  <img src={d.img} alt='...' className='team-img' />
                  <div className='caption'>
                    <h4>{d.name}</h4>
                    <p>
                      {d.job}
                      <br/>
                      {d.email}
                      <br/>
                      {d.phone}
                    </p>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}