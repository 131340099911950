import MCJ from "../common/def"
import { promoConfirm, promoGetInfo, promoSignUp } from "../store/promoSlice"

export function APIGetPromotionInfo(code) {
    return async dispatch => {
        try {
            dispatch(promoGetInfo({
                status:MCJ.STATUS_LOADING
            }))
            let rsp = await fetch(`/api/anonymous/customer?code=${code}`)
            if (rsp.status === 200) {
                let data = (await rsp.json()).data
                dispatch(promoGetInfo({
                    status:MCJ.STATUS_LOAD_OK,
                    data,
                    code
                }))
            } else {
                dispatch(promoGetInfo({
                    status:MCJ.STATUS_ERROR
                }))
            }
        } catch (err) {
            dispatch(promoGetInfo({
                status:MCJ.STATUS_ERROR
            }))
        }
    }
}

export function ConfirmPromotionInfo() {
    return dispatch => {
        dispatch(promoConfirm({
            status:MCJ.STATUS_CONFIRMED
        }))
    }
}

// {
//     "email": "string",
//     "password": "string",
//     "company": "string",
//     "association": "string",
//     "fullName": "string",
//     "contactEmail": "string",
//     "phone": "string",
//     "fax": "string",
//     "address": "string",
//     "city": "string",
//     "state": "string",
//     "postal": "string",
//     "country": "string",
//     "dealer": true,
//     "ffLicense": "string",
//     "ffExpiration": "string",
//     "taxId": "string",
//     "note": "string",
//     "mobileId": "string",
//     "mobileToken": "string",
//     "code": "string"
// }
export function APISignUpWithCode(accountInfo) {
    return async dispatch => {
        try {
            dispatch(promoSignUp({
                status:MCJ.STATUS_LOADING
            }))
            let rsp = await fetch('/api/signup/code', {
                method:'POST',
                body:JSON.stringify(accountInfo)
            })
            if(rsp.status === 201) {
                let data = (await rsp.json()).data
                dispatch(promoSignUp({
                    status:MCJ.STATUS_LOAD_OK,
                    data
                }))
            } else {
                let data = (await rsp.json()).data
                dispatch(promoSignUp({
                    status:MCJ.STATUS_ERROR,
                    errorMessage: data.message
                }))
            }
        } catch(err) {
            dispatch(promoSignUp({
                status:MCJ.STATUS_ERROR,
                errorMessage: err
            }))
        }
    }
}