import { createSlice } from '@reduxjs/toolkit'
import MCJ from "../common/def"

const cartSlice = createSlice({
  name: "cartSlice",
  initialState: {
      shoppingStatus: MCJ.SHOPPING_INIT,
      orderList: [],
      orderLoadStatus: MCJ.STATUS_INIT
  },
  reducers: {
    addToCart: (state, action) => {
      state = action.payload
    },
    getCart: (state, action) => {
      state.cart = action.payload
    },
    getOrder: (state, action) => {
      state.orderList = action.payload.data || []
      state.orderLoadStatus = action.orderLoadStatus
    },
    payOrder: (state, action) => {
      state.paymentStatus = action.payload
    },
  }
})

export const {
  addToCart,
  getCart,
  getOrder,
  payOrder
} = cartSlice.actions

export default cartSlice