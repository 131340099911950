import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { APIGetProductDetail } from '../../../apis/products'
import { Button, Modal, Spinner, Dropdown } from 'react-bootstrap'
import { APIAddToCart } from '../../../apis/cart'
import './product.css'
import ImgGroup from './imageGroup'
import MCJ from '../../../common/def'
import { Link } from 'react-router-dom'
import { withRouter } from '../../../common/withRouter'

class Product extends Component {

    constructor() {
        super()
        this.state = {
            quantity:1,
            dialogShow:false,
            status:MCJ.STATUS_LOADING,
            canAddToCart:false
        }
    }

    async componentDidMount() {
        const { id, productDetailsById } = this.props
        if (typeof productDetailsById[id] === 'undefined' || productDetailsById[id] === null) {
            await this.props.APIGetProductDetail(id)
        }
        this.setState({
            status:MCJ.STATUS_OK,
            canAddToCart: this.canAddToCart()
        })
    }

    addToCart = () => {
        const { id, authed, productDetailsById, cartState } = this.props
        if(authed !== true) {
            window.open('/signin', '_self')
        } else {
            let product = productDetailsById[id]
            if (typeof product === 'undefined' || product === null) {
                return
            }
            let quantity = parseInt(this.state.quantity)
            if (isNaN(quantity) || quantity <= 0) {
                return
            }
            // Append the quantity of the previous order
            const { cart } = cartState
            if (typeof cart !== 'undefined' && cart !== null && cart.length > 0) {
                const previousOrders = cart.filter(order => order.productId === product.id)
                if (previousOrders.length > 0) {
                    const previousOrder = previousOrders[0]
                    quantity += previousOrder.quantity
                }
            }
            this.props.APIAddToCart(product, quantity)
            this.setState({
                dialogShow:true
            })
        }
    }

    handleClose = () => {
        this.setState({
            dialogShow:false
        })
    }

    getQuantityFromCart = () => {
        const { id, productDetailsById } = this.props
        let product = productDetailsById[id]
        const cart = this.props.cartState?.cart
        if (typeof cart === 'undefined' || cart === null || typeof product === 'undefined' || product === null) {
            return undefined
        } else if (cart.length > 0) {
            const previousOrders = cart.filter(order => order.productId === product.id)
            if (previousOrders.length > 0) {
                const previousOrder = previousOrders[0]
                return previousOrder.quantity
            } else {
                return 0
            }
        }
    }

    isQuantityAcceptable = () => {
        let quantity = parseInt(this.state.quantity)
        if (isNaN(quantity)) {
            quantity = 0
        }
        const { id, productDetailsById } = this.props
        let product = productDetailsById[id]
        if (typeof product === 'undefined' || product === null) {
            return false
        }
        // Get the quantity of the previous order
        const cartQuantity = this.getQuantityFromCart()
        if (typeof cartQuantity !== 'undefined' && cartQuantity !== null && (quantity + cartQuantity) > 10) {
            return false
        }
        const prices = product.prices
        // If there's only listing price
        const hasPrices = (typeof prices !== 'undefined' && prices !== null && prices.length > 0)
        if (!hasPrices) {
            return true
        }
        // If user is dealer
        const { userInfo } = this.props.userState
        const userIsDealer = typeof userInfo !== 'undefined' && userInfo !== null && userInfo.dealer === true
        const dealerPrice = prices.filter(price => price.dealer === true)
        if (userIsDealer && dealerPrice.length > 0) {
            return true
        }
        // Check other prices
        for (let i = 0; i < prices.length; i++) {
            const price = prices[i]
            if (price.dealer === true) { // Skip Dealer Price
                continue
            } else if (i < prices.length - 1 && quantity >= price.min && quantity <= price.max) { // Quantity is in a range
                return parseFloat(price.price) !== 0 // If price isn't "0.00", then it's acceptable
            } else if (i === prices.length - 1 && quantity >= price.min) { // Last range
                return parseFloat(price.price) !== 0 // If price isn't "0.00", then it's acceptable
            }
        }
        return false
    }

    isOnStock = () => {
        const { id, productDetailsById } = this.props
        let product = productDetailsById[id]
        if (typeof product === 'undefined' || product === null) {
            return false
        }
        return product["status"] === "OnStock"
    }

    canAddToCart = () => {
        return this.isQuantityAcceptable() && this.isOnStock()
    }

    onValueChange = e => {
        this.setState({
            [e.target.name]:e.target.value,
            canAddToCart: this.canAddToCart()
        })
    }

    renderCartModal = (product) => {
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        let prodImg = MCJ.DefaultImgPrefix + product.thumbnail
        return (
            <Modal show={this.state.dialogShow} onHide={this.handleClose} >
                <div className='cartDialogHeader'>
                    <h1 className='cartDialogTitle'>Add to Cart</h1>                            
                </div>
                <hr style={{ margin: '0px' }}/>
                <Modal.Body>
                    <div className='cartDialogBody'>
                        <div>
                            <img className='cartDialogImg' src={prodImg} alt='item' />
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                            </svg>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='cartDialogFoot'>
                    <Link to={`${region}/cart`}>
                        <Button variant="warning" onClick={this.handleClose}>Go to Cart</Button>
                    </Link>
                    <Button variant="warning" onClick={this.handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderFeatures = (product) => {
        if (typeof product.features !== 'undefined' && product.features !== null) {
            return product.features.map(spec => {
                return <li key={spec.order}>
                    <span>{spec.feature}</span>
                </li>
            })
        } else {
            return null
        }
    }

    renderBizTips = (product) => {
        let isBiz = false
        //
        var re = /.*B$/;
        isBiz = re.exec(product.msku)
        if (isBiz) {
            return <div style={{ margin: '8px' }}>
                For bulk orders of 5 or more, or to schedule future orders, please email us at <span className='tipEmail'>info@mcjtools.com</span> with your requirements to receive discounted pricing.               
            </div>
        } else {
            return <div style={{ margin: '8px' }}>
                For bulk order, please contact sales for best price.
            </div>
        }
    }

    renderDistributors = (product) => {
        const distributors = product.distributors || []
        if (distributors.length === 0) {
            return null
        }
        return <div>
            <hr style={{ margin: '8px' }}/>
            <h5 style={{ margin: '8px' }}>Distributors</h5>
            {
                distributors.map((distributor, i) => {
                    return (
                        <div key={`${distributor.id}`} style={{ margin: '8px'}}>
                            <a href={distributor.site} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'gray' }}>
                                <div className="card h-100">
                                    <h5 className="card-header text-truncate">{distributor.name}</h5>
                                    <div className="card-body mt-2">
                                        <h6 className="card-subtitle">{distributor.contact}</h6>
                                        <p className="card-text">
                                            {distributor.email}
                                            <br/>
                                            {distributor.phone}
                                        </p>
                                    </div>
                                    <div className="card-footer text-truncate">
                                        <small className="text-muted">{distributor.site}</small>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )
                })
            }
        </div>
    }

    renderImages = (product) => {
        let filteredImgs = product.images
        var imgRe = /.*thumbnail\.jpg$/
        if (product && product.images) {
            filteredImgs = product.images.filter(imgurl => !imgRe.exec(imgurl) )
        }
        return <ImgGroup imgPrefix={MCJ.DefaultImgPrefix} imgList={filteredImgs} />
    }

    renderPrices = (product) => {
        const prices = product.prices
        const hasPrices = (typeof prices !== 'undefined' && prices !== null)
        let priceComponent = <span className='textPrice textPriceRed'> ${product.price}</span>
        let otherPrices = undefined
        let dealerPrice = undefined
        let listingPrice = (
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-1px', marginLeft: '-1px', padding: '4px', border: '1px solid #ddd' }}
                key='listing'>
                <span className='textPriceQuantity'>Listing</span>
                <span className='textPrice textPriceStrikeThrough'> ${product.price}</span>
            </div>
        )
        if (hasPrices) {
            // Price Ranges
            const pricesWithoutDealer = prices.filter(price => price.dealer !== true && parseFloat(price.price) !== 0)
            // Special logic to show the only price range as product price
            if (pricesWithoutDealer.length === 1) {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-1px', marginLeft: '-1px', padding: '4px', border: '1px solid #ddd' }}
                        key='price'>
                        <span className='textPriceQuantity'>Price</span>
                        <span className='textPrice textPriceRed'> ${pricesWithoutDealer[0].price}</span>
                    </div>
                )
            }
            otherPrices = pricesWithoutDealer.map((price, index) => {
                const strikeThrough = dealerPrice !== null && 'textPriceStrikeThrough' // If there's dealer price, and user is dealer, strike through all other prices
                const highlighted = (dealerPrice === null &&
                                    ((this.state.quantity >= price.min && this.state.quantity <= price.max) ||
                                        (index === pricesWithoutDealer.length - 1 && this.state.quantity >= price.min))) && 'textPriceRed' // Last price
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-1px', marginLeft: '-1px', padding: '4px', border: '1px solid #ddd' }}
                        key={price.min}>
                        { index === pricesWithoutDealer.length - 1 ? (
                            <span className={`textPriceQuantity`}>{price.min}+</span>
                        ) : (
                            <span className={`textPriceQuantity`}>{price.min}-{price.max}</span>
                        )}
                        <span className={`textPrice ${strikeThrough} ${highlighted}`}> ${price.price}</span>
                    </div>
                )
            })
            // Dealer Price
            const { userInfo } = this.props.userState
            const userIsDealer = typeof userInfo !== 'undefined' && userInfo !== null && userInfo.dealer === true
            dealerPrice = prices.filter(price => price.dealer === true && userIsDealer).map(price => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-1px', marginLeft: '-1px', padding: '4px', border: '1px solid #ddd' }}
                        key='dealer'>
                        <span className='textPriceQuantity'>Dealer</span>
                        <span className='textPrice textPriceRed'> ${price.price}</span>
                    </div>
                )
            })
            dealerPrice = dealerPrice.length > 0 ? dealerPrice[0] : null
        }
        priceComponent = (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                { listingPrice }
                { otherPrices }
                { dealerPrice }
            </div>
        )
        return priceComponent
    }

    render() {
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        const { id, authed, productDetailsById } = this.props
        let product = productDetailsById[id]
        const cartQuantity = this.getQuantityFromCart()
        if (typeof product !== 'undefined' && product !== null) {
            const features = this.renderFeatures(product)
            const imagesComponent = this.renderImages(product)
            const priceComponent = this.renderPrices(product)
            const bizTips = this.renderBizTips(product)
            const distributors = this.renderDistributors(product)
            const cartModal = this.renderCartModal(product)
            return (
                <div className="productContainer container">
                    {imagesComponent}
                    <div className="productInfo">
                        <h2>{product.name}</h2>
                        <hr/>
                        {(typeof product.msku !== 'undefined' && product.msku !== null && product.msku.length > 0) && <p>SKU: {product.msku}</p>}
                        { (region !== "/au" && region !== "/nz") && (
                            <div>
                                <p>Price:</p>
                                {priceComponent}
                                <br/>
                            </div>
                        )}
                        <span>
                            <ul>
                                {features}
                            </ul>
                        </span>
                    </div>
                    { (region !== "/au" && region !== "/nz") && (
                        <div className="productOpt">
                            <div style={{ margin: '10px' }}>
                                {priceComponent}
                            </div>
                            <div style={{ margin: '10px' }} className={this.isOnStock() ? 'textInStock' : 'textOutOfStock'}>
                                { this.isOnStock() ? "In Stock" : "Out of Stock" }
                            </div>
                            <div style={{ display: 'flex', margin: '10px' }}>
                                Quantity:&nbsp;
                                <Dropdown>
                                        <Dropdown.Toggle variant="warning" style={{
                                            width: '100%',
                                            fontSize: '12px',
                                            paddingTop: '0px',
                                            paddingBottom: '0px',
                                            paddingLeft: '8px',
                                            paddingRight: '8px',
                                            cursor: 'pointer'
                                        }}>
                                            {this.state.quantity}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {
                                                [1,2,3,4,5,6,7,8,9,10].map(qty => {
                                                    return (
                                                        <Dropdown.Item
                                                            key={qty}
                                                            active={qty === this.state.quantity}
                                                            disabled={qty + cartQuantity > 10}
                                                            name='quantity'
                                                            value={qty}
                                                            style={{ fontSize: '12px' }}
                                                            onClick={async (e) => {
                                                                e.target.value = qty
                                                                this.onValueChange(e)
                                                            }}>
                                                            {qty}
                                                        </Dropdown.Item>
                                                    )
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                            </div>
                            <div style={{ margin: '10px' }}>
                                <Button variant="warning" disabled={authed && !this.canAddToCart()} className='fullWidth' onClick={this.addToCart}>
                                    Add to Cart
                                </Button>
                            </div>
                            {bizTips}
                            {distributors}
                        </div>
                    )}
                    {cartModal}
                </div>
            )
        } else {
            if (this.state.status === MCJ.STATUS_LOADING) {
                return (
                    <div style={{ paddingTop: '80px', textAlign: 'center' }}>
                        <Spinner animation='border' style={{ marginTop: '80px', marginBottom: '80px'}} />
                    </div>
                )
            } else {
                return (
                    <div style={{ paddingTop: '80px', textAlign: 'center'}}>
                        <h3 style={{ marginTop: '80px', marginBottom: '80px', color: 'GrayText' }}>Product Not Found</h3>
                        <hr/> 
                        <Link to={`${region}/`}>
                            <Button variant="warning" style={{ marginBottom: '16px' }}>Continue shopping</Button>
                        </Link>     
                    </div>
                )
            }
        }
    }
}

const mapStateToProps = state => ({
    productDetailsById: state.prodState.productDetailsById,
    cartState: state.cartState,
    userState: state.userState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetProductDetail,
        APIAddToCart
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Product))