import React, { Component } from 'react'
import { Outlet } from 'react-router'
import NavBar from './navbar/navbar'
import { Footer } from "./footer";
import './home.css'
import { bindActionCreators } from 'redux'
import { LoadSession } from '../../apis/auth'
import { APIGetCategories, APIGetProductsByCategory, APIGetProducts } from '../../apis/products'
import { APIGetSystem } from '../../apis/user'
import { APIGetCart } from '../../apis/cart'
import { connect } from 'react-redux'
import '../../common/common.css'

class Home extends Component {

    async componentDidMount() {
        await this.props.APIGetSystem()
        await this.props.LoadSession()
        // Load categories
        await this.props.APIGetCategories()
        // Load products by category
        const { categories } = this.props.prodState
        categories.forEach( async category => {
            await this.props.APIGetProductsByCategory(category.id)
        });
        // Load products
        await this.props.APIGetProducts()
        // Get Cart
        const { authed } = this.props.authState
        if ( authed ) {
            await this.props.APIGetCart()
        }
    }

    render() {
        return (
            <div>
                <NavBar authed={this.props.authed}/>            
                <Outlet />
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    authState: state.authState,
    prodState: state.prodState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetCategories,
        APIGetProductsByCategory,
        APIGetProducts,
        LoadSession,
        APIGetCart,
        APIGetSystem
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)